<template>
	<div class="container">
		<div class="row">
			<div class="col-md-7 col-sm-12 col-12">
				<img class="w-100" :src="require('../../assets/img/icon/nft_03.png')" alt="">
			</div> 
			<div class="col-md-5 col-sm-12 col-12 mt-5">
				<h1 class="c-fff nft-title">{{store.state.lang.NftPageTitle}}</h1>
				<h6 class="mt-3 c-fff" style="font-weight: 400;">{{store.state.lang.NftPageTitleSub}}</h6>
				<div class="ls-flex-ac mt-3 nft-bar">
					<div class="ls-flex-1 ls-flex-ajc item-bar" @click="changeDaoIndex(0)" :class="{'active':daoIndex==0}">DAOs</div>
					<div class="ls-flex-1 ls-flex-ajc item-bar" @click="changeDaoIndex(1)" :class="{'active':daoIndex==1}">Nodes</div>
					<!-- <div class="ls-flex-1 ls-flex-ajc item-bar" @click="changeDaoIndex(2)" :class="{'active':daoIndex==2}">Nodes</div> -->
				</div>
				<div class="ls-flex-ajb mt-5">
					<div class="minus" @click="minusNftNum()">
						<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="#620000"
							class="bi bi-dash-circle" viewBox="0 0 16 16">
							<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
						 <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
						</svg>
					</div>
					<div class="nft-num">{{mintNum}}</div>
					<div class="add" @click="addNftNum()">
						<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="#620000"
							class="bi bi-plus-circle" viewBox="0 0 16 16">
						 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
							<path
								d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
						</svg>
					</div>
					<h4 class="ml-5 c-fff">{{store.state.lang.w3bull}}</h4>
				</div>
				
				<div class="mt-5 el-btn ls-flex-ajc ls-btn" @click="approveUsdt(daoIndex)" v-if="!approveUsdtState">{{store.state.lang.approve}} USDT</div>
				<div class="mt-5 el-btn ls-flex-ajc ls-btn" @click="mintNFT()" v-else>{{store.state.lang.mint}}</div>
				
				<div class="ls-flex-ajb mt-3 usdt-info c-fff">
					<div>{{store.state.lang.price}}</div>
					<div>
						<span v-if="daoIndex==0">{{daoFormatePrice}}</span>
						<span v-else-if="daoIndex==1">{{nodeFormatePrice}}</span>
						<span v-else>{{nodeCommonFormatePrice}}</span>
						USDT
					</div>
				</div>
			</div>
		</div>
		<h1 class="mt-5 c-fff mb-4">{{store.state.lang.SuperBull}}</h1>
		<div class="nft-desc c-fff" v-for="(item,index) in store.state.lang.NftPageInfo" :key="index">
			{{item}}
		</div>
		<div class="ls-flex-ajc mt-5 ls-h">
			<div class="ls-flex-ajc ls-flex-1">
				<img class="h-img" :src="require('../../assets/img/icon/h_1.png')" alt="">
			</div>
			<div class="ls-flex-ajc ls-flex-1">
				<img class="h-img" :src="require('../../assets/img/icon/h_2.png')" alt="">
			</div>
			<div class="ls-flex-ajc ls-flex-1">
				<img class="h-img" :src="require('../../assets/img/icon/h_3.png')" alt="">
			</div>
			<div class="ls-flex-ajc ls-flex-1">
				<img class="h-img" :src="require('../../assets/img/icon/h_4.png')" alt="">
			</div>
		</div>
	</div>
	
	<comModal :showState="showModalState" :State="stateNum" :StateInfo="StateInfo" :tHash="tHash"></comModal>
</template>

<script setup>
	import {ref,onMounted,watch} from 'vue';
	import {useStore} from 'vuex';
	import {useRouter} from 'vue-router';
	import { approveUsdtAddr,bullvcAddr,relationAddr } from '@/utils';
	import { relationAbi } from '@/utils/relationAbi.json';
	import { approveAbi } from '@/utils/approveAbi.json';
	import { bullvcAbi } from '@/utils/bullvcAbi.json';
	import Web3 from "web3";
	import { ElMessage,ElMessageBox} from 'element-plus';
	import verify from '@/utils/verify.js';
	import comModal  from "@/components/com-modal/com-modal.vue";
	
	const showModalState = ref(false);
	const StateInfo = ref('');
	const stateNum = ref(0);
	const tHash = ref('');
	
	const store = useStore();
	const route = useRouter();
	
	const daoIndex = ref(0);
	const mintNum = ref(1);
	const approveUsdtNum = ref(0);
	const approveUsdtState = ref(false);
	
	const daoPausedState = ref(true);
	const daoPrice = ref(0);
	const daoFormatePrice = ref(0);
	const canMinDaoNum = ref(0);
	
	const nodePausedState = ref(true);
	const nodePrice = ref(0);
	const nodeFormatePrice = ref(0);
	const canMinNodeNum = ref(0);
	
	const nodeCommonPausedState = ref(true);
	const nodeCommonPrice = ref(0);
	const nodeCommonFormatePrice = ref(0);
	const canMinNodeCommonNum = ref(0);
	const nftId = ref('');
	
	import {provider} from "@/utils/provider.js";
	
	var web3js;
	if(store.state.isNet == 1){
		if (typeof window.ethereum === "undefined") {
			//û��װMetaMaskǮ�����е�����ʾ
			web3js = new Web3();
		} else {
			web3js = new Web3(ethereum);
		}
	}
	else{
		web3js = new Web3(provider);
	}
	
	const approveUsdtContract = new web3js.eth.Contract(approveAbi,approveUsdtAddr);
	const bullvcContract = new web3js.eth.Contract(bullvcAbi,bullvcAddr);
	const relationContract = new web3js.eth.Contract(relationAbi,relationAddr);
	
	
	// Doo��غ�Լ
	const initDaoInfo = ()=>{
		bullvcContract.methods.daoWhiteList(store.state.account).call().then((result)=>{
			canMinDaoNum.value = result.mintNumber;
			daoPrice.value = result.mintPrice;
			daoFormatePrice.value = web3js.utils.fromWei(daoPrice.value, 'ether');
			initApproveState();
			
		});
	}
	
	
	// Node��غ�Լ
	const initNodeInfo = ()=>{
		bullvcContract.methods.nodeWhiteList(store.state.account).call().then((result)=>{
			canMinNodeNum.value = result.mintNumber;
			nodePrice.value = result.mintPrice;
			nodeFormatePrice.value = web3js.utils.fromWei(nodePrice.value, 'ether');
		});
	}
	
	// Node Common ��غ�Լ
	const initNodeCommonInfo = ()=>{
		bullvcContract.methods.nodeCommonPaused().call().then((result)=>{
			nodeCommonPausedState.value = result;
		});
		
		bullvcContract.methods.maxPurchase().call().then((result)=>{
			canMinNodeCommonNum.value = result;
		});
		
		bullvcContract.methods.commonUnitPrice().call().then((result)=>{
			nodeCommonPrice.value = result;
			if(nodeCommonPrice.value>0){
				nodeCommonFormatePrice.value = web3js.utils.fromWei(nodeCommonPrice.value, 'ether');
			}
			else{
				nodeCommonFormatePrice.value = 0;
			}
			
		});
	}
	
	const initData = ()=>{
		if(store.state.account){
			// ��ѯ��Ȩ���
			approveUsdtContract.methods.allowance(store.state.account,bullvcAddr).call().then((res)=>{
				approveUsdtNum.value = res;
			});
			
			bullvcContract.methods.daoPaused().call().then((res)=>{
				daoPausedState.value = res;
				initDaoInfo();
			});
			
			bullvcContract.methods.nodeWhitePaused().call().then((res)=>{
				nodePausedState.value = res;
				initNodeInfo();
				
			});
			//mint nodes�����nftid ,����Ƿ��nftid
			relationContract.methods.inviteNode(store.state.account).call().then((res)=>{
				if(res!=0){
					nftId.value = res;
				}
			})
			
			initNodeCommonInfo();
		}
	}
	
	onMounted(()=>{
		initData();
	})
	
	watch(store.state,(news,olds)=>{
		initData();
	})
	
	const initApproveState = ()=>{
		if(daoIndex.value==0){
			approveUsdtState.value = daoPrice.value*mintNum.value>approveUsdtNum.value ? false : true;
		}
		else if(daoIndex.value==1){
			approveUsdtState.value = nodePrice.value*mintNum.value>approveUsdtNum.value ? false : true;
		}
		else{
			approveUsdtState.value = nodeCommonPrice.value*mintNum.value>approveUsdtNum.value ? false : true;
		}
	}
	
	const approveUsdt = (index)=>{
		if(!store.state.account){
			ElMessage('No wallet is connected, please connect the wallet first');
			return false;
		}
		if(!verify.verifyChainId(store.state.chainId)){
			return false;
		}
		
		let approveNum;
		if(index ==0){
			approveNum= BigInt(daoPrice.value*canMinDaoNum.value).toString();
		}
		else if(index==1){
			approveNum= BigInt(nodePrice.value*canMinNodeNum.value).toString();
		}
		else{
			approveNum= BigInt(nodeCommonPrice.value*canMinNodeCommonNum.value).toString();
		}
		
		showModalState.value = true;
		StateInfo.value = 'Approve';
		stateNum.value = 1;
		
		approveUsdtContract.methods.approve(bullvcAddr,approveNum).send({
			from:store.state.account
		}).then((res)=>{
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			
			approveUsdtContract.methods.allowance(store.state.account,bullvcAddr).call().then((result)=>{
				approveUsdtNum.value = result;
				initApproveState();
			});
		},(err)=>{
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}
	
	const mintDao = ()=>{
		showModalState.value = true;
		StateInfo.value = 'Mint';
		stateNum.value = 1;
		
		bullvcContract.methods.mintWhiteDao(mintNum.value).send({
			from:store.state.account
		}).then((res)=>{
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			
			initDaoInfo();
		},(err)=>{
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}
	
	const mintNode = ()=>{
		showModalState.value = true;
		StateInfo.value = 'Mint';
		stateNum.value = 1;
		
		bullvcContract.methods.mintWhiteNode(mintNum.value).send({
			from:store.state.account
		}).then((res)=>{
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			initNodeInfo();
		},(err)=>{
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}
	
	const mintNodeCommon = ()=>{
		showModalState.value = true;
		StateInfo.value = 'Mint';
		stateNum.value = 1;
		
		bullvcContract.methods.mintCommonNode(mintNum.value).send({
			from:store.state.account
		}).then((res)=>{
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			
			initNodeCommonInfo();
		},(err)=>{
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}
	
	const mintNFT = ()=>{
		if(!verify.verifyChainId(store.state.chainId)){
			return false;
		}
		if(!store.state.account){
			ElMessage('No wallet is connected, please connect the wallet first');
			return false;
		}
		
		if(!mintNum.value){
			ElMessage('The number of mints is 0.');
			return false;
		}
		if(daoIndex.value==0){
			if(daoPausedState.value || canMinDaoNum.value==0){
				ElMessage(`Can't cast Dao for the time being.`);
			}
			else{
				mintDao();
			}
			
		}
		else{
			if(!nftId.value){
				ElMessageBox.confirm(
				    'Please bind NFT ID first',
				    'Tips',
				    {
				      confirmButtonText: 'OK',
				      cancelButtonText: 'Cancel',
				      type: 'info',
					  cancelButtonClass:'cancelBtnClass',
					  confirmButtonClass:'confrimBtnClass',
					  customClass:'msgClass',
					  autofocus:false
				    }
				)
				.then(() => {
				    route.push('/bind');
				})
				return false;
			}
			
			if(daoIndex.value==1){
				if(nodePausedState.value || canMinNodeNum.value==0){
					ElMessage(`Can't cast Node for the time being.`);
				}
				else{
					mintNode();
				}
				
			}
			else{
				if(nodeCommonPausedState.value || canMinNodeCommonNum.value==0){
					ElMessage(`Can't cast Node111 for the time being.`);
				}
				else{
					mintNodeCommon();
				}
			}
		}
	}
	
	
	const changeDaoIndex = (index)=>{
		daoIndex.value = index;
		initApproveState();
	}
	
	const addNftNum = ()=>{
		if(daoIndex.value==0){
			if(mintNum.value<canMinDaoNum.value){
				mintNum.value++;
				initApproveState();
			}
		}
		else if(daoIndex.value==1){
			if(mintNum.value<canMinNodeNum.value){
				mintNum.value++;
				initApproveState();
			}
		}
		else{
			if(mintNum.value<canMinNodeCommonNum.value){
				mintNum.value++;
				initApproveState();
			}
		}
	}
	
	const minusNftNum = ()=>{
		if(mintNum.value>1){
			mintNum.value--;
			initApproveState();
		}
		else{
			return false;
		}
	}
	
</script>

<style scoped>
	.nft-bar {
		height: 42px;
		padding: 2px;
		box-sizing: border-box;
		border: 1px solid #620000;
		border-radius: 20px;
	}
	.nft-bar .item-bar {
		height: inherit;
		color: #620000;
		font-size: 18px;
		font-weight: 500;
		cursor: pointer;
	}

	.nft-bar .item-bar.active {
		height: 34px;
		background-color: #620000;
		color: #fff;
		border-radius: 20px;
	}

	.minus,
	.add {
		width: 44px;
		height: 44px;
		cursor: pointer;
	}

	.nft-num {
		padding: 0 15px;
		font-size: 32px;
		font-weight: 400;
		color:#fff;
	}

	.el-btn {
		height: 42px;
		border-radius: 20px;
	}

	.usdt-info {
		font-weight: 500;
		font-size: 16px;
	}
	.nft-desc{
		font-size: 16px;
		line-height:30px;
		text-align: justify;
	}
	.ls-h .h-img{
		width:160px;
	}
	@media (max-width:768px) {
		.nft-title{
			font-size:32px;
			font-weight:500;
			line-height:36px;
		}
		.nft-desc{
			font-size: 14px;
			line-height:24px;
		}
		.ls-h .h-img{
			width:80px;
		}
	}
</style>
